var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading && _vm.$vuetify.breakpoint.smAndUp)?_c('v-col',{staticClass:"pa-0 pr-1 heritage-scroll fill-height",attrs:{"cols":"12","sm":"6"}},[_c('v-card',{staticClass:"heritage-scroll flex-column flex-grow-1"},[_c('v-card-title',[_c('v-row',{staticClass:"align-center flex-nowrap",staticStyle:{"width":"100%"},attrs:{"no-gutters":""}},[_c('v-text-field',{staticClass:"flex-grow-1",attrs:{"test-auto":_vm.nomPage + '_rechercher',"autofocus":"","prepend-inner-icon":"mdi-magnify","label":"Rechercher","clearable":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"mx-2"},'span',attrs,false),on),[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"test-auto":_vm.nomPage + '_filtre_tachesTerminees',"hide-details":"","on-icon":"mdi-flag-plus","off-icon":"mdi-flag-remove-outline"},model:{value:(_vm.afficherLesTachesTerminees),callback:function ($$v) {_vm.afficherLesTachesTerminees=$$v},expression:"afficherLesTachesTerminees"}})],1)]}}],null,false,3253004012)},[(_vm.afficherLesTachesTerminees)?_c('span',[_vm._v("Masquer les tâches terminées")]):_c('span',[_vm._v("Afficher les tâches terminées")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"mx-2"},'span',attrs,false),on),[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"test-auto":_vm.nomPage + '_filtre_tachesProjetArchivees',"hide-details":"","on-icon":"mdi-archive","off-icon":"mdi-archive-outline"},model:{value:(_vm.afficherLesTachesProjetArchivees),callback:function ($$v) {_vm.afficherLesTachesProjetArchivees=$$v},expression:"afficherLesTachesProjetArchivees"}})],1)]}}],null,false,1561966288)},[(_vm.afficherLesTachesProjetArchivees)?_c('span',[_vm._v("Masquer les tâches des projets clôturés")]):_c('span',[_vm._v("Afficher les tâches des projets clôturés")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"mx-2"},'span',attrs,false),on),[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"test-auto":_vm.nomPage + '_filtre_tachesParNom',"hide-details":"","on-icon":"mdi-sort-alphabetical-ascending","off-icon":"mdi-sort-calendar-ascending"},model:{value:(_vm.trierLesTachesParNom),callback:function ($$v) {_vm.trierLesTachesParNom=$$v},expression:"trierLesTachesParNom"}})],1)]}}],null,false,292642883)},[(_vm.trierLesTachesParNom)?_c('span',[_vm._v("Trier les tâches par date de création")]):_c('span',[_vm._v("Trier les tâches par nom")])])],1),_c('v-row',{staticClass:"mt-2 align-center flex-nowrap",staticStyle:{"width":"100%"},attrs:{"no-gutters":""}},[_c('div',[_c('v-select',{staticClass:"test",staticStyle:{"width":"200px"},attrs:{"test-auto":_vm.nomPage + '_absence',"attach":"","value":_vm.configuration.itemSelectionnePourPointage,"items":_vm.typesAbsence,"background-color":"#ffa500","solo":"","dense":"","hide-details":"","label":"Saisir une absence ..."},on:{"input":_vm.selectionnerPourPointage}})],1),_c('v-spacer'),(_vm.configuration.ressourcesSelectionnee != null)?_c('v-chip',{attrs:{"color":"red darken-3","close":"","text-color":"white"},on:{"click:close":_vm.clearRessourcesSelectionnee}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v("mdi-account-circle")])],1),_vm._v(" "+_vm._s(("Vue de " + (_vm.getPrenomNom(_vm.ressourcesSelectionnee[0], false, true))))+" ")],1):_vm._e()],1)],1),_c('v-card-text',{staticClass:"scrollable-content"},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',_vm._l((_vm.tachesFiltrees),function(tache){return _c('v-list-item',{key:tache.idTache,staticClass:"list-item",attrs:{"dense":"","ripple":false},on:{"click":function($event){return _vm.selectionnerPourPointage(tache.idTache)}}},[[_c('div',{staticClass:"item"},[_c('div',{staticClass:"info-tache"},[_c('v-list-item-avatar',{attrs:{"tile":""}},[_c('v-avatar',{class:_vm.getContrastYIQ(tache.couleur)
                          ? 'black--text'
                          : 'white--text',attrs:{"x-small":"","tile":"","color":tache.couleur}},[_c('span',{staticClass:"px-1"},[_vm._v(" "+_vm._s((tache.tempsPrevu - tache.tempsPointe).toFixed(1))+" JH ")])])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(tache.nom))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(((tache.dateCreationTache) + " | " + (_vm.getPrenomNom( tache.idRessourceResponsable )) + " | " + (_vm.projets[tache.idProjet].nomProjet)))+" ")])],1)],1),(_vm.estSelectionneePourPointage(tache.idTache))?_c('div',{staticClass:"slider"},[_c('slider-avancement-tache',{attrs:{"tache":_vm.items[tache.idTache],"nom-page":_vm.nomPage}})],1):_vm._e()])]],2)}),1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }