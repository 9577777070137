<template>
  <v-card-text class="scrollable-content flex-grow-1">
    <v-calendar
      ref="calendar"
      v-model="focusDate"
      color="primary"
      type="week"
      :weekdays="weekday"
      :events="events"
      :event-ripple="false"
      :first-interval="heureDebutJournee"
      :interval-count="heureFinJournee - heureDebutJournee"
      @mousedown:event="startDrag"
      @mousedown:time="startTime"
      @mousemove:time="mouseMove"
      @mouseup:time="endDrag"
      @mouseleave.native="cancelDrag"
      @change="chargerLesEvenements"
    >
      <template v-slot:event="{ event, eventParsed, timed }" @click.native.stop>
        <div
          :class="{ active: hover }"
          class="pointage"
          @mouseover="
            hover = true;
            afficherDetaillePointage(event, eventParsed);
          "
          @mouseleave="hover = false"
        >
          <div class="v-event-draggable">
            <div
              :class="
                getContrastYIQ(event.color) ? 'black--text' : 'white--text'
              "
            >
              <div>
                <div class="text-truncate" style="width: 100%">
                  <strong>
                    {{ event.name }}
                  </strong>
                </div>
                <strong>
                  {{ getDureePointage(event.start, event.end) }}
                </strong>

                <p>
                  {{ eventParsed.start.time + " - " + eventParsed.end.time }}
                </p>
              </div>
              <div
                v-if="!event.AutreEntreprise"
                style=" position: absolute;
                right: 0;
                bottom:0;"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" class="mx-2" v-on="on">
                      <v-icon
                        :color="getContrastYIQ(event.color) ? 'black' : 'white'"
                        @click="completerJournee(event)"
                        @click.native.stop
                      >
                        mdi-chevron-down
                      </v-icon>
                    </span>
                  </template>
                  <span>Completer la journée</span>
                </v-tooltip>
                <v-icon
                  :color="getContrastYIQ(event.color) ? 'black' : 'white'"
                  @click="supprimerPointage(event)"
                  @click.native.stop
                >
                  mdi-delete
                </v-icon>
              </div>
            </div>
          </div>
          <div
            v-if="timed && !event.AutreEntreprise"
            class="v-event-drag-bottom"
            @mousedown.stop="extendBottom(event)"
          ></div>
        </div>
      </template>
    </v-calendar>
    <div v-if="hover">
      <div
        :class="
          getContrastYIQ(eventhover.color) ? 'black--text' : 'white--text'
        "
        :style="{ background: eventhover.color }"
        class="info-pointage"
      >
        <div class="text-truncate" style="width: 100%">
          <strong>
            {{ eventhover.name }}
          </strong>
        </div>
        <div class="info-duree-pointage">
          <strong>
            {{ getDureePointage(eventhover.start, eventhover.end) }}
          </strong>
          <span>
            {{ eventhover.starttime + " - " + eventhover.endtime }}
          </span>
        </div>
      </div>
    </div>
  </v-card-text>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import {
  getContrastYIQ,
  timestampToHeure,
  getDateOfTimestamp,
} from "@/utils/transform";
import { EventHub } from "@/event-hub";
export default {
  props: {
    nomPage: {
      type: String,
      default: "",
    },
    items: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    listePointage: {
      type: Array,
      default: null,
    },
    ressources: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    focusDate: "",
    events: [],
    weekday: [1, 2, 3, 4, 5, 6, 0],
    dragEvent: null,
    dragStart: null,
    createEvent: null,
    createStart: null,
    extendOriginal: null,
    heureDebutJournee: 7,
    heureFinJournee: 22,
    ready: false,
    iconClicked: false,
    hover: false,
    eventhover: {},
  }),
  computed: {
    ...mapGetters("entreprise", ["getRole", "getEntreprisebyIdRessource"]),
    ...mapState("entreprise", ["entreprises", "profils", "utilisateurs"]),
    ...mapState("utilisateur", ["utilisateur", "configuration"]),
    couleurSelectionActive: function() {
      return this.configuration.itemSelectionnePourPointage
        ? this.items[this.configuration.itemSelectionnePourPointage].couleur
        : "black";
    },
    idRessource: function() {
      return this.configuration.idRessourceSelectionnee > 0
        ? this.configuration.idRessourceSelectionnee
        : this.utilisateur.idRessource;
    },
  },
  watch: {
    listePointage: function(listePointage) {
      const events = [];
      for (let i = 0; i < listePointage.length; i++) {
        let idItem;
        let estUneAbsence;
        let idPointageOuAbsence;
        let name;
        let color;
        let AutreEntreprise;
        if (!(listePointage[i].idRessource in this.utilisateurs)) {
          name = this.entreprises[
            this.ressources.filter(
              (ressource) =>
                ressource.idRessource === listePointage[i].idRessource
            )[0].idEntreprise
          ].nomEntreprise;
          color = "#A9A9A9";
          AutreEntreprise = true;
        } else {
          name =
            "idAbsence" in listePointage[i]
              ? listePointage[i].typeAbsence.nomTypeAbsence
              : this.items[listePointage[i].idTache].nom;
          color =
            "idAbsence" in listePointage[i]
              ? this.items[listePointage[i].typeAbsence.nomTypeAbsence].couleur
              : this.items[listePointage[i].idTache].couleur;
          AutreEntreprise = false;
        }
        let start = Date.parse(
          listePointage[i].date + " " + listePointage[i].heureDebut
        );
        let end = Date.parse(
          listePointage[i].date + " " + listePointage[i].heureFin
        );
        if ("idAbsence" in listePointage[i]) {
          idItem = listePointage[i].typeAbsence.nomTypeAbsence;
          estUneAbsence = true;
          idPointageOuAbsence = listePointage[i].idAbsence;
        } else {
          idItem = listePointage[i].idTache;
          estUneAbsence = false;
          idPointageOuAbsence = listePointage[i].idPointage;
        }

        events.push({
          name: name,
          color: color,
          start: start,
          end: end,
          idRessource: listePointage[i].idRessource,
          timed: true,
          idItem: idItem,
          estUneAbsence: estUneAbsence,
          idPointageOuAbsence: idPointageOuAbsence,
          AutreEntreprise: AutreEntreprise,
        });
      }
      this.events = events;
    },
  },
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    getContrastYIQ: getContrastYIQ,
    timestampToHeure: timestampToHeure,
    getDateOfTimestamp: getDateOfTimestamp,
    setToday() {
      this.focusDate = "";
    },
    prev() {
      this.$refs.calendar.move(-1);
    },
    next() {
      this.$refs.calendar.move(1);
    },
    startDrag({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      } else if (!event.AutreEntreprise) {
        this.dragTime = 1000;
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms);
      if (this.dragEvent && this.dragTime === null) {
        if (!this.dragEvent.AutreEntreprise) {
          const start = this.dragEvent.start;
          this.dragTime = mouse - start;
        }
      } else if (this.configuration.itemSelectionnePourPointage) {
        this.creeNouveauEvent(this.roundTime(mouse));
      } else {
        EventHub.$emit("AFFICHER_NOTIFICATION", {
          message: "Il faut sélectionner une tâche",
          color: "black",
          icon: "mdi-open-in-new",
        });
      }
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);
      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        if (this.valideDrag(this.dragEvent, newStart, newEnd)) {
          this.dragEvent.start = newStart;
          this.dragEvent.end = newEnd;
        }
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        if (this.valideDrag(this.createEvent, min, max)) {
          this.createEvent.start = min;
          this.createEvent.end = max;
        } else {
          //
        }
      }
    },
    async endDrag() {
      await this.timeout(5);
      if (!this.extendOriginal && this.createEvent) {
        this.creePointage({
          date: this.getDateOfTimestamp(this.createEvent.start),
          start: this.timestampToHeure(this.createEvent.start),
          end: this.timestampToHeure(this.createEvent.end),
        });
      } else if (this.extendOriginal && this.createEvent && !this.iconClicked) {
        this.modifierPointage(this.createEvent);
      } else if (this.dragEvent && !this.iconClicked) {
        this.modifierPointage(this.dragEvent);
      }
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
      this.iconClicked = false;
    },
    cancelDrag() {
      if (this.extendOriginal) {
        this.createEvent.end = this.extendOriginal;
      } else {
        const i = this.events.indexOf(this.createEvent);
        if (i !== -1) {
          this.events.splice(i, 1);
        }
      }

      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
    },
    roundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    creeNouveauEvent(start) {
      this.createStart = start;
      let idItem;
      let estUneAbsence;
      let idPointageOuAbsence;
      let nom;
      if (
        "idTypeAbsence" in
        this.items[this.configuration.itemSelectionnePourPointage]
      ) {
        idItem = this.items[this.configuration.itemSelectionnePourPointage]
          .nomTypeAbsence;
        estUneAbsence = true;
        idPointageOuAbsence = this.items[
          this.configuration.itemSelectionnePourPointage
        ].idAbsence;
        nom = this.items[this.configuration.itemSelectionnePourPointage]
          .nomTypeAbsence;
      } else {
        idItem = this.items[this.configuration.itemSelectionnePourPointage]
          .idTache;
        estUneAbsence = false;
        idPointageOuAbsence = this.items[
          this.configuration.itemSelectionnePourPointage
        ].idPointage;
        nom = this.items[this.configuration.itemSelectionnePourPointage].nom;
      }
      this.createEvent = {
        name: nom,
        color: this.items[this.configuration.itemSelectionnePourPointage]
          .couleur,
        start: this.createStart,
        end: this.createStart + 3600000, //start + 1heure
        timed: true,
        idItem: idItem,
        estUneAbsence: estUneAbsence,
        idPointageOuAbsence: idPointageOuAbsence,
      };
      if (
        this.valideDrag(
          this.createEvent,
          this.createEvent.start,
          this.createEvent.end
        )
      ) {
        this.events.push(this.createEvent);
      } else {
        this.createEvent = null;
      }
    },
    creePointage({ date, start, end }) {
      this.$emit("pointer-demi-journee", { date, start, end });
    },
    modifierPointage(event) {
      this.$emit("modifier-pointage", {
        estUneAbsence: event.estUneAbsence,
        idPointageOuAbsence: event.idPointageOuAbsence,
        idItem: event.idItem,
        idRessource: event.idRessource,
        date: this.getDateOfTimestamp(event.start),
        start: this.timestampToHeure(event.start),
        end: this.timestampToHeure(event.end),
      });
    },
    chargerLesEvenements(event) {
      this.$emit("charger-les-evenements", event);
    },
    supprimerPointage(event) {
      this.iconClicked = true;
      this.$emit("supprimer-demi-journee", {
        estUneAbsence: event.estUneAbsence,
        idASupprimer: event.idPointageOuAbsence,
        idItem: event.idItem,
        idRessource: event.idRessource,
        start: this.timestampToHeure(event.start),
        end: this.timestampToHeure(event.end),
      });
    },
    getDureePointage(start, end) {
      const tmp = end - start;
      const h = Math.floor(tmp / 3600000);
      const m = "0" + Math.floor((tmp % 3600000) / 60000);
      return h + ":" + m.substr(-2);
    },
    //condition de validation d'un pointage
    valideDrag(event, min, max) {
      //si date debut et date de fin d'un pointage sur jour differnant
      //et si heure de debut avant 7h ou heure fin apres 22h
      if (
        this.getDateOfTimestamp(min) !== this.getDateOfTimestamp(max) ||
        new Date(min).getHours() < this.heureDebutJournee ||
        new Date(max).getHours() * 60 + new Date(max).getMinutes() >
          this.heureFinJournee * 60
      ) {
        return false;
      }
      for (let i = 0; i < this.events.length; i++) {
        if (this.events[i] !== event) {
          //verifier le chevochement d'un pointage
          if (
            (this.events[i].end < max && this.events[i].end > min) ||
            (this.events[i].start < max && this.events[i].start > min) ||
            (this.events[i].start < max && this.events[i].end > max) ||
            (this.events[i].start < min && this.events[i].end > min) ||
            (min == this.events[i].start && max == this.events[i].end)
          ) {
            return false;
          }
        }
      }
      return true;
    },
    timeout(ms) {
      //pass a time in milliseconds to this function
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    completerJournee(event) {
      console.log("test");
      this.iconClicked = true;
      let sommeTempspointer = 0;
      for (let i = 0; i < this.events.length; i++) {
        if (
          getDateOfTimestamp(event.start) ===
          getDateOfTimestamp(this.events[i].start)
        ) {
          sommeTempspointer +=
            (this.events[i].end - this.events[i].start) / 1000;
        }
      }
      let journeEnMinute =
        this.profils[
          [
            this.getEntreprisebyIdRessource(event.idRessource),
            this.getRole(event.idRessource),
          ]
        ].tempsTravaille * 60;

      let tempsAAjouter = journeEnMinute - sommeTempspointer / 60;
      event.end += tempsAAjouter * 60000;
      this.modifierPointage(event);
    },
    afficherDetaillePointage(event, eventParsed) {
      this.eventhover = {
        ...event,
        starttime: eventParsed.start.time,
        endtime: eventParsed.end.time,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}

.info-pointage {
  padding-left: 5px;
  padding-top: 5px;
  display: block;
  position: absolute;
  border-radius: 4px;
  top: 10px;
  left: 10px;
  height: 50px;
  width: 150px;
  z-index: 2;
}
.info-duree-pointage {
  display: flex;
  justify-content: space-between;
}
.text-truncate {
  flex: 1;
  min-width: 0; /* or some value */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pointage {
  display: inline-block;
  height: 100%;
  width: 100%;
}
</style>
