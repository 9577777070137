var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-text',{staticClass:"scrollable-content flex-grow-1"},[_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","type":"week","weekdays":_vm.weekday,"events":_vm.events,"event-ripple":false,"first-interval":_vm.heureDebutJournee,"interval-count":_vm.heureFinJournee - _vm.heureDebutJournee},on:{"mousedown:event":_vm.startDrag,"mousedown:time":_vm.startTime,"mousemove:time":_vm.mouseMove,"mouseup:time":_vm.endDrag,"change":_vm.chargerLesEvenements},nativeOn:{"mouseleave":function($event){return _vm.cancelDrag($event)}},scopedSlots:_vm._u([{key:"event",fn:function(ref){
var event = ref.event;
var eventParsed = ref.eventParsed;
var timed = ref.timed;
return [_c('div',{staticClass:"pointage",class:{ active: _vm.hover },on:{"mouseover":function($event){_vm.hover = true;
          _vm.afficherDetaillePointage(event, eventParsed);},"mouseleave":function($event){_vm.hover = false}}},[_c('div',{staticClass:"v-event-draggable"},[_c('div',{class:_vm.getContrastYIQ(event.color) ? 'black--text' : 'white--text'},[_c('div',[_c('div',{staticClass:"text-truncate",staticStyle:{"width":"100%"}},[_c('strong',[_vm._v(" "+_vm._s(event.name)+" ")])]),_c('strong',[_vm._v(" "+_vm._s(_vm.getDureePointage(event.start, event.end))+" ")]),_c('p',[_vm._v(" "+_vm._s(eventParsed.start.time + " - " + eventParsed.end.time)+" ")])]),(!event.AutreEntreprise)?_c('div',{staticStyle:{"position":"absolute","right":"0","bottom":"0"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"mx-2"},'span',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.getContrastYIQ(event.color) ? 'black' : 'white'},on:{"click":function($event){return _vm.completerJournee(event)}},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,true)},[_c('span',[_vm._v("Completer la journée")])]),_c('v-icon',{attrs:{"color":_vm.getContrastYIQ(event.color) ? 'black' : 'white'},on:{"click":function($event){return _vm.supprimerPointage(event)}},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" mdi-delete ")])],1):_vm._e()])]),(timed && !event.AutreEntreprise)?_c('div',{staticClass:"v-event-drag-bottom",on:{"mousedown":function($event){$event.stopPropagation();return _vm.extendBottom(event)}}}):_vm._e()])]}}]),model:{value:(_vm.focusDate),callback:function ($$v) {_vm.focusDate=$$v},expression:"focusDate"}}),(_vm.hover)?_c('div',[_c('div',{staticClass:"info-pointage",class:_vm.getContrastYIQ(_vm.eventhover.color) ? 'black--text' : 'white--text',style:({ background: _vm.eventhover.color })},[_c('div',{staticClass:"text-truncate",staticStyle:{"width":"100%"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.eventhover.name)+" ")])]),_c('div',{staticClass:"info-duree-pointage"},[_c('strong',[_vm._v(" "+_vm._s(_vm.getDureePointage(_vm.eventhover.start, _vm.eventhover.end))+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.eventhover.starttime + " - " + _vm.eventhover.endtime)+" ")])])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }