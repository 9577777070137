<template>
  <v-col
    v-if="!loading"
    cols="12"
    sm="6"
    class="pa-0 pl-1 heritage-scroll fill-height"
  >
    <v-card class="heritage-scroll flex-column flex-grow-1">
      <v-card-title class="pa-0">
        <v-toolbar flat width="100%">
          <v-btn
            :loading="loadingElement || loadingElements"
            :test-auto="nomPage + '_bouton_aujourdhui'"
            class="mr-4"
            :class="
              getContrastYIQ(couleurSelectionActive)
                ? 'black--text'
                : 'white--text'
            "
            :color="couleurSelectionActive"
            @click="setToday"
          >
            Aujourd'hui
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :test-auto="nomPage + '_bouton_moisPrecedant'"
            fab
            small
            text
            color="grey darken-2"
            @click="prev"
          >
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.CalendrierPointage">
            {{ $refs.CalendrierPointage.$refs.calendar.title }}
          </v-toolbar-title>
          <v-btn
            :test-auto="nomPage + '_bouton_moisSuivant'"
            fab
            small
            text
            color="grey darken-2"
            @click="next"
          >
            <v-icon> mdi-chevron-right </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-select
            v-if="AutorisePointageALHeure(ressourcesSelectionnee[0])"
            v-model="vueSelected"
            :items="typeVue"
            :menu-props="{ top: true, offsetY: true }"
            style="width : 100px"
            solo
            dense
            hide-details
          >
          </v-select>
          <v-btn
            :disabled="loadingElement || loadingElements"
            :test-auto="nomPage + '_bouton_exporter'"
            class="ml-4 gradient-bleu"
            dark
            @click="ouvrirExportPointage"
          >
            Exporter
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <calendrier-mois
        v-if="vueSelected == 'mois'"
        ref="CalendrierPointage"
        :nom-page="nomPage"
        :items="items"
        :loading="loading"
        :liste-pointage="listePointage"
        :loading-elements="loadingElements"
        :loading-element="loadingElement"
        :ressources="ressources"
        @charger-les-evenements="chargerPointage"
        @supprimer-demi-journee="supprimerPointage"
        @pointer-demi-journee="CreePointage"
      />
      <calendrier-semaine
        v-else
        ref="CalendrierPointage"
        :nom-page="nomPage"
        :items="items"
        :loading="loading"
        :liste-pointage="listePointage"
        :ressources="ressources"
        @charger-les-evenements="chargerPointage"
        @supprimer-demi-journee="supprimerPointage"
        @pointer-demi-journee="CreePointage"
        @modifier-pointage="modifierPointage"
      />
    </v-card>
  </v-col>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import Api from "@/api/kuberact";
import { getContrastYIQ, heureFormaterToMinutes } from "@/utils/transform";
import { EventHub } from "@/event-hub";
import CalendrierMois from "@/components/CalendrierMois.vue";
import CalendrierSemaine from "@/components/CalendrierSemaine.vue";
export default {
  components: {
    CalendrierSemaine,
    CalendrierMois,
  },
  props: {
    nomPage: {
      type: String,
      default: "",
    },
    items: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    loadingElements: false,
    loadingElement: false,
    listePointage: [],
    focusDate: "",
    dateDebut: "",
    finDebut: "",
    vueSelected: "mois",
    typeVue: ["mois", "semaine"],
    ressources: [],
    //
  }),
  computed: {
    ...mapState("entreprise", ["entreprises", "utilisateurs", "profils"]),
    ...mapState("utilisateur", ["utilisateur", "configuration"]),
    ...mapGetters("entreprise", [
      "getRole",
      "AutorisePointageALHeure",
      "getEntreprisebyIdRessource",
    ]),
    ...mapGetters("utilisateur", [
      "ressourcesSelectionnee",
      "idRessourcesSelectionneePourPointage",
      "ListRessourceToRessourceIdList",
    ]),
    couleurSelectionActive: function() {
      return this.configuration.itemSelectionnePourPointage
        ? this.items[this.configuration.itemSelectionnePourPointage].couleur
        : "black";
    },
    idRessource: function() {
      this.configuration.itemSelectionnePourPointage;
      return this.idRessourcesSelectionneePourPointage(this.items);
    },
  },
  watch: {
    //
  },
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    ...mapActions("entreprise", [
      "recupererUtilisateurs",
      "recupererRoles",
      "recupererProjets",
      "recupererEntreprises",
      "recupererGroupes",
    ]),
    getContrastYIQ: getContrastYIQ,
    heureFormaterToMinutes: heureFormaterToMinutes,
    setToday() {
      this.$refs.CalendrierPointage.setToday();
    },
    prev() {
      this.$refs.CalendrierPointage.prev();
    },
    next() {
      this.$refs.CalendrierPointage.next();
    },
    estValide(date) {
      return (
        !this.loadingElement &&
        date in this.elementsCalendrier &&
        this.elementsCalendrier[date]["formatDemiJourneeValide"]
      );
    },
    CreePointage({ date, start, end }) {
      this.loadingElement = true;
      if (this.configuration.itemSelectionnePourPointage === null) {
        EventHub.$emit("AFFICHER_NOTIFICATION", {
          message: "Il faut sélectionner une tâche",
          color: "black",
          icon: "mdi-open-in-new",
        });
      } else {
        this.loadingElement = true;
        const dateCreation = new Date().toISOString().slice(0, 10);

        let payload = {
          date: date,
          heureDebut: start,
          heureFin: end,
          idRessource: this.idRessource,
        };

        if (
          "nomTypeAbsence" in
          this.items[this.configuration.itemSelectionnePourPointage]
        ) {
          let typeAbsence = {
            nomTypeAbsence: this.items[
              this.configuration.itemSelectionnePourPointage
            ].nomTypeAbsence,
            idTypeAbsence: this.items[
              this.configuration.itemSelectionnePourPointage
            ].idTypeAbsence,
          };
          // Création d'une absence
          Api.createAbsence(
            Object.assign(payload, {
              dateCreationAbsence: dateCreation,
              typeAbsence: typeAbsence,
            })
          )
            .then((response) => {
              const absence = response.data;
              this.listePointage.push(absence);
            })
            .catch((err) => console.log(err))
            .finally(() => (this.loadingElement = false));
        } else {
          // Création d'un pointage
          Api.createPointage(
            Object.assign(payload, {
              idTache: this.configuration.itemSelectionnePourPointage,
              dateCreationPointage: dateCreation,
            })
          )
            .then((response) => {
              const pointage = response.data;
              this.listePointage.push(pointage);
              // Mise à jour du temps pointé
              this.items[
                this.configuration.itemSelectionnePourPointage
              ].tempsPointe += this.calculeTempsPointer(
                pointage.heureDebut,
                pointage.heureFin,
                pointage.idRessource
              );
            })
            .catch((err) => console.log(err))
            .finally(() => (this.loadingElement = false));
        }
      }
    },
    modifierPointage({
      estUneAbsence,
      idPointageOuAbsence,
      idItem,
      idRessource,
      date,
      start,
      end,
    }) {
      this.loadingElement = true;
      let fonctionModification;
      if (estUneAbsence) {
        // C'est une absence
        fonctionModification = Api.updateAbsence;
      } else {
        // C'est un pointage
        fonctionModification = Api.updatePointage;
      }

      let payload = {
        date: date,
        heureDebut: start,
        heureFin: end,
      };
      fonctionModification(idPointageOuAbsence, payload)
        .then((response) => {
          let pointage = response.data;
          // Actualise la quantité de pointage sur la tâche
          if (!estUneAbsence) {
            // donc pas défaut est un pointage
            this.items[idItem].tempsPointe += this.calculeTempsPointer(
              start,
              end,
              idRessource
            );
          }
          let indexPointageModifier;
          this.listePointage.forEach((element, index) => {
            if (estUneAbsence) {
              if (element.idAbsence == idPointageOuAbsence) {
                indexPointageModifier = index;
              }
            } else {
              // C'est un pointage
              if (element.idPointage == idPointageOuAbsence) {
                indexPointageModifier = index;
                this.items[idItem].tempsPointe -= this.calculeTempsPointer(
                  element.heureDebut,
                  element.heureFin,
                  element.idRessource
                );
              }
            }
          });
          this.listePointage[indexPointageModifier].heureDebut =
            pointage.heureDebut;
          this.listePointage[indexPointageModifier].heureFin =
            pointage.heureFin;
          this.listePointage[indexPointageModifier].date = pointage.date;
        })
        .catch((err) => console.log(err))
        .finally(() => (this.loadingElement = false));
    },
    supprimerPointage({
      estUneAbsence,
      idASupprimer,
      idItem,
      idRessource,
      start,
      end,
    }) {
      // stringDemiJournee: 'matin' | 'soir'
      this.loadingElement = true;

      let fonctionSuppression;
      if (estUneAbsence) {
        // C'est une absence
        fonctionSuppression = Api.destroyAbsence;
      } else {
        // C'est un pointage
        fonctionSuppression = Api.destroyPointage;
      }

      fonctionSuppression(idASupprimer)
        .then(() => {
          // Actualise la quantité de pointage sur la tâche
          if (!estUneAbsence) {
            // donc pas défaut est un pointage
            this.items[idItem].tempsPointe -= this.calculeTempsPointer(
              start,
              end,
              idRessource
            );
          }
          let indexPointageAsupprimer;
          this.listePointage.forEach((element, index) => {
            if (estUneAbsence) {
              if (element.idAbsence == idASupprimer) {
                indexPointageAsupprimer = index;
              }
            } else {
              // C'est un pointage
              if (element.idPointage == idASupprimer) {
                indexPointageAsupprimer = index;
              }
            }
          });
          this.listePointage.splice(indexPointageAsupprimer, 1);
        })
        .catch((err) => console.log(err))
        .finally(() => (this.loadingElement = false));
    },
    chargerPointage(event) {
      this.loadingElements = true;
      this.dateDebut = event.start.date;
      this.dateFin = event.end.date;

      Api.getUtilisateur(
        this.utilisateurs[this.ressourcesSelectionnee[0]].idUtilisateur
      ).then((response) => {
        this.ressources = response.data.ressources;
        let idRessourceList = this.ressources.map(function(ressource) {
          return ressource.idRessource;
        });
        Promise.all([
          // Charger les pointages
          Api.getPointagesPourUneListeDeRessourceParPeriode(
            idRessourceList,
            event.start.date,
            event.end.date
          )
            .then((response) => response.data)
            .catch((err) => console.log(err)),

          // Charger les absences
          Api.getAbsencesPourUneListeDeRessourceParPeriode(
            idRessourceList,
            event.start.date,
            event.end.date
          )
            .then((response) => response.data)
            .catch((err) => console.log(err)),
        ])
          .then(([pointages, absences]) => {
            // Traitement et ajout au calendrier
            this.listePointage = pointages.concat(absences);
          })
          .finally(() => (this.loadingElements = false));
      });
    },
    // pointerLaJournee(objetDate) {
    // TODO: pointer la journée quand on clic sur un jour du mois en cours
    // console.log(objetDate);
    // console.log(this.$refs.calendar);
    //
    // },
    //calculer le temps pointer en jour selon le profils de l'utilisateur selectioné
    calculeTempsPointer(start, end, idRessource) {
      let differanceStartEnd =
        this.heureFormaterToMinutes(end) - this.heureFormaterToMinutes(start);
      let journeEnMinute =
        this.profils[
          [
            this.getEntreprisebyIdRessource(idRessource),
            this.getRole(idRessource),
          ]
        ].tempsTravaille * 60;
      return differanceStartEnd / journeEnMinute;
    },
    ouvrirExportPointage() {
      EventHub.$emit("AFFICHER_FORMULAIRE", {
        type: "export-pointages",
        id: this.ressources,
        data: {
          date: this.$refs.CalendrierPointage.$refs.calendar.lastEnd.date,
          listePointage: this.listePointage,
          taches: this.items,
          title: this.$refs.CalendrierPointage.$refs.calendar.title,
        },
      });
    },
  },
};
</script>

<style scoped>
::v-deep .v-chip__content {
  width: 100%;
}

::v-deep .v-calendar-weekly__day-label .v-btn {
  height: 30px;
  width: 30px;
}

.truncated-if-too-long {
  flex: 1;
  min-width: 0; /* or some value */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
