<template>
  <v-container
    fluid
    fill-height
    pa-2
    class="conteneur-page gradient-bleu align-start heritage-scroll flex-row"
  >
    <!-- TODO: gérer la version mobile et arrêter de caché la liste des tâches pour les petits écrans -->
    <v-skeleton-loader
      v-if="loading"
      :loading="loading"
      type="list-item-avatar, divider,divider, card-heading, card-heading, card-heading, card-heading, card-heading"
      class="flex-grow-1"
      style="background-color: white"
    />

    <liste-tache
      :nom-page="nomPage"
      :types-absence="typesAbsence"
      :items="items"
      :loading="loading"
    />

    <calendrier-pointage
      :nom-page="nomPage"
      :items="items"
      :loading="loading"
    />
  </v-container>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import Api from "@/api/kuberact";
import { convertArrayToObject } from "@/utils/transform";
import ListeTache from "@/views/ListeTache";
import CalendrierPointage from "@/views/CalendrierPointage";
import ENUMS from "@/utils/enums";
import { EventHub } from "@/event-hub";
export default {
  components: {
    ListeTache,
    CalendrierPointage,
  },
  data: () => ({
    loading: true,
    nomPage: "pointage",
    items: {},
    typesAbsence: [],
    //
  }),
  computed: {
    ...mapState("entreprise", ["utilisateurs", "roles"]),
    ...mapState("utilisateur", ["utilisateur", "configuration"]),
    ...mapGetters("utilisateur", ["ressourcesSelectionnee"]),
    ...mapGetters("entreprise", ["getRole"]),
  },
  watch: {
    ressourcesSelectionnee: function() {
      this.getItemsPourPointage();
    },
  },
  created() {
    this.getItemsPourPointage();
  },
  mounted() {
    //
  },
  beforeDestroy() {
    //
  },
  methods: {
    ...mapActions("utilisateur", ["updateItemSelectionnePourPointage"]),
    getItemsPourPointage() {
      this.loading = true;
      //on verifier qu'on esseye pas d'acceder a la page pointage d'un administrateur
      if (
        ENUMS.roleAdministateur.includes(
          this.roles[this.getRole(this.ressourcesSelectionnee[0])].nomRole
        )
      ) {
        EventHub.$emit("AFFICHER_NOTIFICATION", {
          message: "Veuillez selectioner un utilisateur valide",
          color: "black",
          icon: "mdi-calendar-month",
        });
        return;
      }
      Api.getTachesPourUneListeDeRessource(this.ressourcesSelectionnee)
        .then((tacheResponse) => {
          let taches = convertArrayToObject(tacheResponse.data, "idTache");
          Api.getTypesAbsence()
            .then((absenceResponse) => {
              let itemAbsence = convertArrayToObject(
                absenceResponse.data,
                "nomTypeAbsence"
              );
              for (const [key] of Object.entries(itemAbsence)) {
                itemAbsence[key]["couleur"] = "#ffa500";
                this.typesAbsence.push(key);
              }
              this.typesAbsence.sort();
              this.items = Object.assign(taches, itemAbsence);

              if (
                !(this.configuration.itemSelectionnePourPointage in this.items)
              ) {
                this.updateItemSelectionnePourPointage(null);
              }
              // Si l'utilisateur arrive avec une tâche sélectionner dans le store,
              // alors on préselectionne cette tâche pour pointage
              if (this.configuration.idTacheSelectionnee in this.items) {
                this.updateItemSelectionnePourPointage(
                  this.configuration.idTacheSelectionnee
                );
              }
            })
            .catch((err) => console.log(err))
            .finally(() => (this.loading = false));
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
<style scoped></style>
