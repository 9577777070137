<template>
  <v-card-text class="scrollable-content flex-grow-1">
    <v-calendar
      ref="calendar"
      v-model="focusDate"
      :weekdays="weekdays"
      @change="chargerLesEvenements"
    >
      <!-- TODO: pointer la journée entière -->
      <!-- @click:date="pointerLaJournee" -->
      <template v-slot:day="{ date, outside }">
        <div
          v-if="
            (!loadingElements && date in elementsCalendrier) ||
              (!loadingElements &&
                !(date in elementsCalendrier) &&
                outside === false)
          "
        >
          <v-row no-gutters>
            <v-chip
              v-if="
                date in elementsCalendrier &&
                  'matin' in elementsCalendrier[date] &&
                  elementsCalendrier[date].matin !== null
              "
              :disabled="!elementsCalendrier[date].matin.DemiJourneeValide"
              label
              small
              close
              :close-icon="
                elementsCalendrier[date].matin.DemiJourneeValide
                  ? 'mdi-delete'
                  : 'mdi-lock'
              "
              :color="elementsCalendrier[date].matin.couleur"
              :class="
                elementsCalendrier[date].matin.estCouleurClaire
                  ? 'black--text'
                  : 'white--text'
              "
              class="flex-grow-1 ma-1 justify-space-between"
              @click:close="supprimerDemiJournee(date, 'matin')"
            >
            <v-tooltip top  :disabled='!elementsCalendrier[date].matin.tooltip'>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" class="text-truncate" style="width: 100%" v-on="on">
               {{ elementsCalendrier[date].matin.nom }}
              </span>
            </template>
            <span
            >
            {{ elementsCalendrier[date].matin.tooltip }}
            </span>
          </v-tooltip>
            </v-chip>
            <v-chip
              v-else
              label
              small
              class="flex-grow-1 ma-1"
              @click="pointerDemiJournee(date, 'matin')"
            >
              matin
            </v-chip>
          </v-row>
          <v-row no-gutters>
            <v-chip
              v-if="
                date in elementsCalendrier &&
                  'soir' in elementsCalendrier[date] &&
                  elementsCalendrier[date].soir !== null
              "
              :disabled="!elementsCalendrier[date].soir.DemiJourneeValide"
              label
              small
              close
              :close-icon="
                elementsCalendrier[date].soir.DemiJourneeValide
                  ? 'mdi-delete'
                  : 'mdi-lock'
              "
              :color="elementsCalendrier[date].soir.couleur"
              :class="
                elementsCalendrier[date].soir.estCouleurClaire
                  ? 'black--text'
                  : 'white--text'
              "
              class="flex-grow-1 ma-1"
              @click:close="supprimerDemiJournee(date, 'soir')"
            >
              <v-tooltip bottom :disabled='!elementsCalendrier[date].soir.tooltip'>
            <template v-slot:activator="{ on, attrs }">

              <span v-bind="attrs" class="text-truncate" style="width: 100%" v-on="on">
               {{ elementsCalendrier[date].soir.nom }}
              </span>

            </template>
                <span>
                {{ elementsCalendrier[date].soir.tooltip }}
                </span>

              </v-tooltip>

            </v-chip>
            <v-chip 
            v-else 
            label small class="flex-grow-1 ma-1" 
            @click="pointerDemiJournee(date, 'soir')"
            >
              soir
            </v-chip>
          </v-row>
        </div>
      </template>
    </v-calendar>
  </v-card-text>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import {
  getContrastYIQ,
  timestampToHeure,
  getDateOfTimestamp,
  minutesToHeureFormater,
  heureFormaterToMinutes,
} from "@/utils/transform";

export default {
  props: {
    nomPage: {
      type: String,
      default: "",
    },
    items: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    listePointage: {
      type: Array,
      default: null,
    },
    loadingElements: {
      type: Boolean,
      default: false,
    },
    loadingElement: {
      type: Boolean,
      default: false,
    },
    ressources: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    focusDate: "",
    weekdays: [1, 2, 3, 4, 5],
    dateDebut: "",
    finDebut: "",
    elementsCalendrier: {},
  }),
  computed: {
    ...mapState("utilisateur", ["utilisateur", "configuration"]),
    ...mapState("entreprise", ["entreprises", "utilisateurs", "profils", "projets"]),
    ...mapGetters("entreprise", [
      "getRole",
      "getEntreprisebyIdRessource",
      "AutorisePointageALHeure",
    ]),
    ...mapGetters("utilisateur", [
      "ressourcesSelectionnee",
      "idRessourcesSelectionneePourPointage",
    ]),
    couleurSelectionActive: function() {
      return this.configuration.itemSelectionnePourPointage
        ? this.items[this.configuration.itemSelectionnePourPointage].couleur
        : "black";
    },
    idRessource: function() {
      this.configuration.itemSelectionnePourPointage;
      return this.idRessourcesSelectionneePourPointage(this.items);
    },
  }, //fin computed

  watch: {
    listePointage: function(listePointage) {
      let elementsCalendrier = {};
      listePointage.forEach((element) => {
        // Initialisation de la structure pour la date de l'element
        if (!(element.date in elementsCalendrier)) {
          elementsCalendrier[element.date] = {
            matin: null,
            soir: null,
          };
        }
        const heureFin = element.heureFin.split(":");
        const heureFinEnMinute = heureFin[0] * 60 + heureFin[1] * 1;
        const stringDemiJournee = heureFinEnMinute <= 840 ? "matin" : "soir";

        if (!(element.idRessource in this.utilisateurs)) {
          if (stringDemiJournee === "soir") {
            const heureDebut = element.heureDebut.split(":");
            const heureDebutEnMinute = heureDebut[0] * 60 + heureDebut[1] * 1;
            if (heureDebutEnMinute < 840) {
              elementsCalendrier[element.date]["matin"] = {
                DemiJourneeValide: false,
                couleur: "black",
                estCouleurClaire: false,
                nom: this.entreprises[
                  this.ressources.filter(
                    (ressource) => ressource.idRessource === element.idRessource
                  )[0].idEntreprise
                ].nomEntreprise,
              };
            }
          }
          elementsCalendrier[element.date][stringDemiJournee] = {};

          elementsCalendrier[element.date][
            stringDemiJournee
          ].nom = this.entreprises[
            this.ressources.filter(
              (ressource) => ressource.idRessource === element.idRessource
            )[0].idEntreprise
          ].nomEntreprise;
          elementsCalendrier[element.date][stringDemiJournee].couleur = "black";
          elementsCalendrier[element.date][
            stringDemiJournee
          ].DemiJourneeValide = false;
        } else {
          //840 = 14:00:00
          const DemiJourneeValide = this.pointageDemiJourneeEstValide(
            stringDemiJournee,
            elementsCalendrier[element.date][stringDemiJournee],
            element
          );
          elementsCalendrier[element.date][stringDemiJournee] = {
            DemiJourneeValide: DemiJourneeValide,
          };
          if (DemiJourneeValide) {
            elementsCalendrier[element.date][stringDemiJournee] = element;
            elementsCalendrier[element.date][stringDemiJournee][
              "DemiJourneeValide"
            ] = DemiJourneeValide;
            if ("idAbsence" in element) {
              // C'est une absence
              elementsCalendrier[element.date][stringDemiJournee].nom =
                element.typeAbsence.nomTypeAbsence;
              elementsCalendrier[element.date][
                stringDemiJournee
              ].couleur = this.items[
                element.typeAbsence.nomTypeAbsence
              ].couleur;
            } else {
              // C'est un pointage -------------------------------------------------------------
              const tache = this.items[element.idTache];               
            elementsCalendrier[element.date][stringDemiJournee].nom = 
             this.projets[tache.idProjet].nomProjet;
             elementsCalendrier[element.date][stringDemiJournee].nomTache = 
             tache.nom;       
              elementsCalendrier[element.date][stringDemiJournee].couleur =
                tache.couleur;
              elementsCalendrier[element.date][stringDemiJournee].tooltip = "Tache: "+ tache.nom;
            }
            // Ajout de l'info de contraste
            elementsCalendrier[element.date][
              stringDemiJournee
            ].estCouleurClaire = getContrastYIQ(
              elementsCalendrier[element.date][stringDemiJournee].couleur
            );
          } else {
            //cas d'un pointage a cheval sur le matin et l'apres midi
            // si un pointage du soir est non valide on verifie qu'il ne commence pas le matin, si oui on bloc aussi le matin
            if (stringDemiJournee === "soir") {
              const heureDebut = element.heureDebut.split(":");
              const heureDebutEnMinute = heureDebut[0] * 60 + heureDebut[1] * 1;
              if (heureDebutEnMinute < 840) {
                elementsCalendrier[element.date]["matin"] = {
                  DemiJourneeValide: false,
                  couleur: "black",
                  estCouleurClaire: false,
                  nom: "Pointage à l'heure",
                };
              }
            }
            elementsCalendrier[element.date][stringDemiJournee] = {
              DemiJourneeValide: DemiJourneeValide,
              couleur: "black",
              estCouleurClaire: false,
              nom: "Pointage à l'heure",
            };
          }
        }
      });
      this.elementsCalendrier = elementsCalendrier;
    },
  },
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    getContrastYIQ: getContrastYIQ,
    timestampToHeure: timestampToHeure,
    getDateOfTimestamp: getDateOfTimestamp,
    heureFormaterToMinutes: heureFormaterToMinutes,
    minutesToHeureFormater: minutesToHeureFormater,
    setToday() {
      this.focusDate = "";
    },
    prev() {
      this.$refs.calendar.move(-1);
    },
    next() {
      this.$refs.calendar.move(1);
    },
    // estValide(date) {
    //   return (
    //     !this.loadingElement &&
    //     date in this.elementsCalendrier &&
    //     this.elementsCalendrier[date]["formatDemiJourneeValide"]
    //   );
    // },

    pointageDemiJourneeEstValide(stringDemiJournee, DemiJournee, element) {
      if (
        !(
          element.heureDebut ===
            this.journee(element.idRessource)[stringDemiJournee].heureDebut &&
          element.heureFin ===
            this.journee(element.idRessource)[stringDemiJournee].heureFin
        ) ||
        DemiJournee != null
      ) {
        //ne pas bloquer les pointage au mois si les pointage a la semaine ne sont pas autoriser pour l'entreprise
        return !this.AutorisePointageALHeure(this.ressourcesSelectionnee[0]);
      }
      return true;
    },
    pointerDemiJournee(date, stringDemiJournee) {
      let start = 0;
      let end = 0;
      if (this.idRessource) {
        start = this.journee(this.idRessource)[stringDemiJournee].heureDebut;
        end = this.journee(this.idRessource)[stringDemiJournee].heureFin;
      }
      this.$emit("pointer-demi-journee", { date, start, end });
    },
    supprimerDemiJournee(date, stringDemiJournee) {
      let idASupprimer;
      let idItem;
      // Identification du type de l'element
      const estUneAbsence =
        "idAbsence" in this.elementsCalendrier[date][stringDemiJournee];
      if (estUneAbsence) {
        // C'est une absence
        idItem = this.elementsCalendrier[date][stringDemiJournee].nom;
        idASupprimer = this.elementsCalendrier[date][stringDemiJournee]
          .idAbsence;
      } else {
        idItem = this.elementsCalendrier[date][stringDemiJournee].idTache;
        idASupprimer = this.elementsCalendrier[date][stringDemiJournee]
          .idPointage;
      }
      this.$emit("supprimer-demi-journee", {
        estUneAbsence,
        idASupprimer,
        idItem,
        idRessource: this.elementsCalendrier[date][stringDemiJournee]
          .idRessource,
        start: this.elementsCalendrier[date][stringDemiJournee].heureDebut,
        end: this.elementsCalendrier[date][stringDemiJournee].heureFin,
      });
    },
    chargerLesEvenements(event) {
      this.$emit("charger-les-evenements", event);
    },

    journee(idRessource) {
      let journee = {
        matin: { heureDebut: null, heureFin: null },
        soir: { heureDebut: null, heureFin: null },
      };
      let tempsDemisJourneEnMinute =
        (this.profils[
          [
            this.ressources.filter(
              (ressource) => ressource.idRessource === idRessource
            )[0].idEntreprise,
            this.getRole(this.ressourcesSelectionnee[0]), //peut importe la ressource le role reste le meme donc on prend le premier de la list
          ]
        ].tempsTravaille *
          60) /
        2;
      //matin
      journee.matin.heureFin = "12:30:00";
      journee.matin.heureDebut = this.minutesToHeureFormater(
        this.heureFormaterToMinutes(journee.matin.heureFin) -
          tempsDemisJourneEnMinute
      );

      //soir
      journee.soir.heureDebut = "14:00:00";
      journee.soir.heureFin = this.minutesToHeureFormater(
        heureFormaterToMinutes(journee.soir.heureDebut) +
          tempsDemisJourneEnMinute
      );

      return journee;
    },
    // pointerLaJournee(objetDate) {
    // TODO: pointer la journée quand on clic sur un jour du mois en cours
    // console.log(objetDate);
    // console.log(this.$refs.calendar);
    //
    // },
  },
};
</script>
<style scoped>
/* Utilisé pour aligner la corbeille à droite des v-chip */
::v-deep .v-chip__content {
  width: 100%;
}

::v-deep .v-calendar-weekly__day-label .v-btn {
  height: 30px;
  width: 30px;
}

.truncated-if-too-long {
  flex: 1;
  min-width: 0; /* or some value */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
