<template>
  <v-col
    v-if="!loading && $vuetify.breakpoint.smAndUp"
    cols="12"
    sm="6"
    class="pa-0 pr-1 heritage-scroll fill-height"
  >
    <v-card class="heritage-scroll flex-column flex-grow-1">
      <v-card-title>
        <v-row no-gutters style="width: 100%" class="align-center flex-nowrap">
          <v-text-field
            v-model="search"
            :test-auto="nomPage + '_rechercher'"
            class="flex-grow-1"
            autofocus
            prepend-inner-icon="mdi-magnify"
            label="Rechercher"
            clearable
            hide-details
            dense
            outlined
          ></v-text-field>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" class="mx-2" v-on="on">
                <v-checkbox
                  v-model="afficherLesTachesTerminees"
                  :test-auto="nomPage + '_filtre_tachesTerminees'"
                  hide-details
                  class="mt-0 pt-0"
                  on-icon="mdi-flag-plus"
                  off-icon="mdi-flag-remove-outline"
                ></v-checkbox>
              </span>
            </template>
            <span v-if="afficherLesTachesTerminees"
              >Masquer les tâches terminées</span
            >
            <span v-else>Afficher les tâches terminées</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" class="mx-2" v-on="on">
                <v-checkbox
                  v-model="afficherLesTachesProjetArchivees"
                  :test-auto="nomPage + '_filtre_tachesProjetArchivees'"
                  hide-details
                  class="mt-0 pt-0"
                  on-icon="mdi-archive"
                  off-icon="mdi-archive-outline"
                ></v-checkbox>
              </span>
            </template>
            <span v-if="afficherLesTachesProjetArchivees"
              >Masquer les tâches des projets clôturés</span
            >
            <span v-else>Afficher les tâches des projets clôturés</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" class="mx-2" v-on="on">
                <v-checkbox
                  v-model="trierLesTachesParNom"
                  :test-auto="nomPage + '_filtre_tachesParNom'"
                  hide-details
                  class="mt-0 pt-0"
                  on-icon="mdi-sort-alphabetical-ascending"
                  off-icon="mdi-sort-calendar-ascending"
                ></v-checkbox>
              </span>
            </template>
            <span v-if="trierLesTachesParNom"
              >Trier les tâches par date de création</span
            >
            <span v-else>Trier les tâches par nom</span>
          </v-tooltip>
        </v-row>
        <v-row
          no-gutters
          style="width: 100%"
          class="mt-2 align-center flex-nowrap"
        >
          <div>
            <v-select
              class="test"
              :test-auto="nomPage + '_absence'"
              attach
              :value="configuration.itemSelectionnePourPointage"
              :items="typesAbsence"
              background-color="#ffa500"
              style="width : 200px"
              solo
              dense
              hide-details
              label="Saisir une absence ..."
              @input="selectionnerPourPointage"
            >
            </v-select>
          </div>
          <v-spacer />
          <v-chip
            v-if="configuration.ressourcesSelectionnee != null"
            color="red darken-3"
            close
            text-color="white"
            @click:close="clearRessourcesSelectionnee"
          >
            <v-avatar left>
              <v-icon>mdi-account-circle</v-icon>
            </v-avatar>
            {{
              `Vue de ${getPrenomNom(ressourcesSelectionnee[0], false, true)}`
            }}
          </v-chip>
        </v-row>
      </v-card-title>
      <v-card-text class="scrollable-content">
        <v-list dense>
          <v-list-item-group>
            <v-list-item
              v-for="tache in tachesFiltrees"
              :key="tache.idTache"
              class="list-item"
              dense
              :ripple="false"
              @click="selectionnerPourPointage(tache.idTache)"
            >
              <template>
                <div class="item">
                  <div class="info-tache">
                    <v-list-item-avatar tile>
                      <v-avatar
                        x-small
                        tile
                        :color="tache.couleur"
                        :class="
                          getContrastYIQ(tache.couleur)
                            ? 'black--text'
                            : 'white--text'
                        "
                      >
                        <span class="px-1">
                          {{
                            (tache.tempsPrevu - tache.tempsPointe).toFixed(1)
                          }}
                          JH
                        </span>
                        <!-- Ne pas afficher de valeur négative: {{ Math.max(0, item.tempsPrevu - item.tempsPointe) }} JH -->
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ tache.nom }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{
                          `${tache.dateCreationTache} | ${getPrenomNom(
                            tache.idRessourceResponsable
                          )} | ${projets[tache.idProjet].nomProjet}`
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </div>
                  <div
                    v-if="estSelectionneePourPointage(tache.idTache)"
                    class="slider"
                  >
                    <slider-avancement-tache
                      :tache="items[tache.idTache]"
                      :nom-page="nomPage"
                    />
                  </div>
                </div>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </v-col>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { getContrastYIQ } from "@/utils/transform";
import SliderAvancementTache from "@/components/SliderAvancementTache";

export default {
  components: {
    SliderAvancementTache,
  },
  props: {
    nomPage: {
      type: String,
      default: "",
    },
    typesAbsence: {
      type: Array,
      default: null,
    },
    items: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    search: "",
    afficherLesTachesTerminees: false,
    trierLesTachesParNom: false,
    afficherLesTachesProjetArchivees: false,
    //
  }),
  computed: {
    ...mapState("entreprise", ["utilisateurs", "roles", "projets"]),
    ...mapState("utilisateur", ["utilisateur", "configuration"]),
    ...mapGetters("entreprise", ["getPrenomNom"]),
    ...mapGetters("utilisateur", ["ressourcesSelectionnee"]),
    tachesFiltrees: function() {
      // Masquer les tâches non-actives
      let listeTaches = Object.values(this.items).filter(
        (tache) => tache.actif
      );

      // Masquet les tâches projet cloturer
      if (!this.afficherLesTachesProjetArchivees) {
        listeTaches = listeTaches.filter(
          (tache) => this.projets[tache.idProjet].actif
        );
      }

      // Masquet les tâches terminées
      if (!this.afficherLesTachesTerminees) {
        listeTaches = listeTaches.filter(
          (tache) =>
            tache.avancement < 1 ||
            (this.projets[tache.idProjet].actif === false && // afficher les tache terminer des projets cloturer
              this.afficherLesTachesProjetArchivees) ||
            tache.idTache === this.configuration.itemSelectionnePourPointage
        );
      }

      // Recherche textuelle
      if (this.search !== null) {
        const search = this.search.toLocaleLowerCase();
        if (search.trim() !== "") {
          listeTaches = listeTaches.filter(
            (tache) =>
              tache.nom
                .toString()
                .toLocaleLowerCase()
                .indexOf(search) !== -1
          );
        }
      }

      // Trier les tâches (default: tri par date de création)
      if (this.trierLesTachesParNom) {
        listeTaches.sort((a, b) => a.nom.localeCompare(b.nom));
      } else {
        listeTaches.sort((a, b) =>
          // oldest last
          b.dateCreationTache.localeCompare(a.dateCreationTache)
        );
      }

      return listeTaches;
    },
  },
  watch: {
    //
  },
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    ...mapActions("utilisateur", [
      "clearRessourcesSelectionnee",
      "updateItemSelectionnePourPointage",
      "updateIdTacheSelectionnee",
    ]),
    getContrastYIQ: getContrastYIQ,
    estSelectionneePourPointage(id) {
      return this.configuration.itemSelectionnePourPointage === id;
    },
    selectionnerPourPointage(id) {
      if (!this.estSelectionneePourPointage(id)) {
        this.updateItemSelectionnePourPointage(id);
      }
    },
  },
};
</script>
<style>
.list-item {
  display: block;
}
.info-tache {
  display: flex;
  height: 50px;
}
.slider {
  display: flex;
}
.test > .v-menu__content > .v-list {
  background: #ffa500;
}
</style>
